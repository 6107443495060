import cn from 'classnames';
import type { SpinnerSize } from './index';

export interface LoaderSpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: SpinnerSize;
  fixed?: boolean;
}

const LoaderSpinner: React.FC<LoaderSpinnerProps> = ({
  fixed,
  size = 'regular',
  className,
  ...props
}) => (
  <div
    className={cn(`loader__spinner`, `loader__spinner--${size}`, className, { fixed: fixed })}
    {...props}
  />
);

export default LoaderSpinner;
